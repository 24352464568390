<template>
  <div>
    <!-- Media -->

    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="
            userData.avatarFileUrl
              ? userData.avatarFileUrl.startsWith('data:image')
                ? userData.avatarFileUrl
                : `data:image/jpeg;base64,${userData.avatarFileUrl}`
              : ''
          "
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>

      <div class="d-flex flex-wrap">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          class="btn-icon ml-0"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />

          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-secondary"
          class="btn-icon ml-0"
          @click="deletePic"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form @submit="onSubmit">
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="6">
          <b-form-group label="Name" label-for="username">
            <b-form-input id="username" v-model="userData.firstName" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="6">
          <b-form-group label="Last Name" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.lastName" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="6">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->

        <!-- Field: Role -->
        <b-col cols="12" md="6">
          <b-form-group label="User Role" label-for="user-role">
            <v-select
              v-model="userData.userRoles"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              multiple
              :reduce="(val) => val.roleId"
              label="roleName"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->

        <b-col cols="12" md="6">
          <b-form-group>
            <b-form-checkbox
              v-model="userData.isDisabled"
              name="check-button"
              switch
              inline
            >
              User Disabled
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Action Buttons -->
      <b-button
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
      >
        Save
      </b-button>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="resetPassword"
      >
        <feather-icon icon="KeyIcon" class="cursor-pointer" />
        Reset Password
      </b-button>
      <!--   <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>-->
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";
import store from "@/store";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { Vue } from "vue-property-decorator";

export default {
  components: {
    BButton,
    ToastificationContent,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      file: null,
    };
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  methods: {
    deletePic() {
      store
        .dispatch("app-user/deletePic", {
          id: this.userData.id,
        })
        .then((response) => {
          this.userData.avatarFileUrl = "";
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
    resetPassword() {
      store
        .dispatch("app-user/resetPassword", {
          id: this.userData.id,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.messages[0],
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();

    const roleOptions = [
      { roleName: "Administrator", roleId: 1 },
      { roleName: "AppUser", roleId: 2 },
    ];

    const onSubmit = (event) => {
      event.preventDefault();

      var selectedIds = [];
      props.userData.userRoles.forEach((value, index) => {
        if (props.userData.userRoles[index].roleId == null) {
          selectedIds.push(props.userData.userRoles[index]);
        } else {
          selectedIds.push(props.userData.userRoles[index].roleId);
        }
      });

      const blankUserData = {
        id: props.userData.id,
        firstName: props.userData.firstName,
        lastName: props.userData.lastName,
        email: props.userData.email,
        userRoleIds: selectedIds,
        isDisabled: props.userData.isDisabled,
      };
      store
        .dispatch("app-user/editUser", blankUserData)
        .then((response) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "User updated successfully",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          router.push("/apps/users/list").catch(() => {});
          // router.go("/apps/users/list").catch(() => {});
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }

          if (error.response.status === 404) {
            skillsData.value = undefined;
          }
        });
    };

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatarFileUrl = base64;
        
        store
          .dispatch("app-user/addPhoto", {
            id: props.userData.id,
            base64: refInputEl.value.files[0],
          })
          .then((response) => {
            console.log("Response:", response);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "error",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: value,
                  },
                });
              }
            } else {
              console.error("Error:", error);
            }

            if (error.response && error.response.status === 404) {
              skillsData.value = undefined;
            }
          });
      }
    );

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      onSubmit,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
